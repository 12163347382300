/*eslint-disable*/
import { MsalService } from "@azure/msal-angular";
import { Guid } from "guid-typescript";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { Validators, UntypedFormControl } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

import { Status } from "../../shared/enums/status.enum";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { ContractService } from "./../../core/services/contract.service";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { Observable, of, Subject } from "rxjs";
import { AuthState, selectorAuth, UserModel, UserService } from "../../core";
import { select, Store } from "@ngrx/store";
import { switchMap, takeUntil } from "rxjs/operators";

@Component({
  selector: "anms-send-module",
  templateUrl: "./send-module.component.html",
  styleUrls: ["./send-module.component.scss"]
})
export class SendModuleComponent implements OnInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  isAuthenticated: any;
  isDigital = new UntypedFormControl("", Validators.required);
  basicInfoValid: any;
  basicInfoGroup: any;
  userName: string;
  public user$: Observable<UserModel>;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  constructor(
    public contractService: ContractService,
    private router: Router,
    private store: Store<any>,
    private userService: UserService,
    private MsalService: MsalService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.user$ = this.store
      .pipe(
        select(selectorAuth),
        takeUntil(this.unsubscribe$),
        switchMap((auth: AuthState) => {
          this.isAuthenticated = auth.isAuthenticated;
          if (this.isAuthenticated) {
            return this.userService.get();
          }

          return of(new UserModel());
        })
      );
    this.user$.subscribe(res => this.userName = res.givenName + " " + res.surname);
  }

  onBasicInfoChanged(event) {
    this.basicInfoValid = event;
  }

  onFileComplete(event: any) {
    if (event) {
      this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "600px",
        data: {
          dialogTitle: "Contract upload",
          dialogMessage: `Your contract has been successfully uploaded in ` + event.contractType + `.`,
          cancelButtonText: "Close"
        }
      });

      this.confirmationDialogRef.afterClosed().subscribe(result => {
        this.router.navigate([""]);
      });
    }
  }

  getData(event) {
    this.basicInfoGroup = event;
  }

  get contract() {
    if (this.basicInfoGroup) {


      const contract = {
        title: this.basicInfoGroup.title,
        isDigital: this.isDigital.value === "true",
        loggedBy: this.userName,
        status: Status.PendingScanning,
        amendment: this.basicInfoGroup.amendment,
        ucbEntityId: this.basicInfoGroup.ucbEntity ? this.basicInfoGroup.ucbEntity.id : undefined,
        thirdParty: this.basicInfoGroup.thirdParty,
        languageGroupId: this.basicInfoGroup.languageGroup ? this.basicInfoGroup.languageGroup.id : undefined,
        originatorId: this.basicInfoGroup.originator ? this.basicInfoGroup.originator.id : undefined,
        contractTypeId: this.basicInfoGroup.contractType ? this.basicInfoGroup.contractType.id : undefined,
        keywords: this.basicInfoGroup.keywords,
        signatureDate: this.basicInfoGroup.signatureDate,
        effectiveDate: this.basicInfoGroup.effectiveDate,
        expiryDate: this.basicInfoGroup.expiryDate
      };

      const formData = new FormData();
      formData.append("ContractDto", JSON.stringify(contract));

      return formData;
    } else {
return undefined;
}
  }

  createCoversheet() {
    this.basicInfoValid = false;
    const isDigital = this.isDigital.value === "true";
    const basicInfoJson = {
      title: this.basicInfoGroup.title,
      isDigital,
      loggedBy: this.userName,
      status: Status.PendingScanning,
      amendment: this.basicInfoGroup.amendment,
      ucbEntityId: this.basicInfoGroup.ucbEntity.id,
      thirdParty: this.basicInfoGroup.thirdParty,
      languageGroupId: this.basicInfoGroup.languageGroup.id,
      originatorId: this.basicInfoGroup.originator.id,
      contractTypeId: this.basicInfoGroup.contractType.id,
      keywords: this.basicInfoGroup.keywords,
      signatureDate: this.basicInfoGroup.signatureDate,
      effectiveDate: this.basicInfoGroup.effectiveDate,
      expiryDate: this.basicInfoGroup.expiryDate
    };
    this.contractService.createCoversheet(basicInfoJson).subscribe(
      result => {
        if (result != Guid.EMPTY) {
this.router.navigate(["/coversheet/" + result]);
}
      },
      error => {
        this.snackBar.open("There was an error when trying to create the contract. Please contact the administrator.",
          null, { panelClass: "snack-error", duration: 5000 });
        this.router.navigate(["/"]);
      }
    );
  }
}
