/*eslint-disable*/
import { TemplateMappingService } from "./../../../core/services/template-mapping.service";
import { MappingTemplateDialogComponent } from "./mapping-template-dialog/mapping-template-dialog.component";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MaterialGridComponent } from "../../../shared/material-grid/material-grid.component";
import { AdminService } from "../../../core/services/admin.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

@Component({
  selector: "anms-mapping-table",
  templateUrl: "./mapping-table.component.html",
  styleUrls: ["./mapping-table.component.scss"]
})
export class MappingTableComponent implements OnInit, AfterViewInit {
  @ViewChild(MaterialGridComponent, { static: true }) grid;
  @ViewChild("activeTemplate", { static: true }) activeTemplate;
  @ViewChild("editTemplate", { static: true }) editTemplate;

  displayedColumns = [
    "edit",
    "contractTypeText",
    "numberOfTPSigners",
    "numberOfUCBSigners",
    "languageGroupText",
    "docuSignTemplateId",
    "docuSignTemplateName",
    "active"
  ];

  columns = [
    {
      name: "edit",
      displayName: "Actions",
      cellTemplate: null
    },
    {
      name: "contractTypeText",
      displayName: "Contract Type"
    },
    {
      name: "numberOfTPSigners",
      displayName: "Number of CP Signers"
    },
    {
      name: "numberOfUCBSigners",
      displayName: "Number of UCB Signers"
    },
    {
      name: "languageGroupText",
      displayName: "Language Group"
    },
    {
      name: "docuSignTemplateId",
      displayName: "DocuSign Template ID"
    },
    {
      name: "docuSignTemplateName",
      displayName: "DocuSign Template Title"
    },
    {
      name: "active",
      displayName: "Active Template?",
      cellTemplate: null
    }
  ];

  constructor(
    public templateMappingService: TemplateMappingService,
    public dialog: MatDialog
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    const activeColumn = this.columns.filter(function(item) {
      return item.name == "active";
    })[0];
    activeColumn.cellTemplate = this.activeTemplate;

    const editColumn = this.columns.filter(function(item) {
      return item.name == "edit";
    })[0];
    editColumn.cellTemplate = this.editTemplate;
  }

  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(MappingTemplateDialogComponent, {
      width: "600px",
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == "Add") {
        this.templateMappingService
          .createTemplate(result.data)
          .subscribe(() => this.grid.search());
      } else if (result.event == "Edit") {
        this.templateMappingService
          .editTemplate(result.data)
          .subscribe(() => this.grid.search());
      }
    });
  }
}
