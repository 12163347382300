/*eslint-disable*/
import { Component, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { NewsBanner } from "../../shared/models/news-banner.model";
import { ActivatedRoute } from "@angular/router";
import { select, Store } from "@ngrx/store";
import { AuthState, selectorAuth, UserModel, UserService } from "../../core";
import { switchMap, takeUntil } from "rxjs/operators";
import { Observable, of, Subject } from "rxjs";

@Component({
  selector: "anms-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  modules = [
    {
      link: "create",
      title: "e-Templates",
      action: "CREATE",
      description:
        "Create e-signature ready contracts from pre-defined templates"
    },
    {
      link: "sign-digitally",
      title: "e-Signature",
      action: "SIGN",
      description:
        "Get your contract signed electronically by all parties through DocuSign"
    },
    {
      link: "send",
      title: "Repository",
      action: "STORE",
      description: "Secure signed contracts into ContractShare"
    },
    {
      link: "my-contracts",
      title: "My Contracts",
      action: "TRACK",
      description: "Monitor status and track your contracts"
    }
  ];
  userName: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  isAuthenticated: any;
  public user$: Observable<UserModel>;

  constructor(
    private adalService: MsalService,
    private store: Store<any>,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }
  newsBanner: NewsBanner[] = [];

  ngOnInit() {
    for (let i = 0; i < this.route.snapshot.data.result.length; i++) {
      this.newsBanner.push(this.route.snapshot.data.result[i]);
    }

    this.user$ = this.store
      .pipe(
        select(selectorAuth),
        takeUntil(this.unsubscribe$),
        switchMap((auth: AuthState) => {
          this.isAuthenticated = auth.isAuthenticated;
          if (this.isAuthenticated) {
            return this.userService.get();
          }

          return of(new UserModel());
        })
      );
    this.user$.subscribe(res => this.userName = res.givenName + " " + res.surname);
  }
}
