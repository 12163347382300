export enum Status {
  Created = 1,
  PendingSignature = 2,
  PendingScanning = 3,
  Signed = 4,
  Archived = 5,
  Invalid = 6
}

export const StatusLabel = new Map<number, string>([
  [Status.Created, "Created"],
  [Status.PendingSignature, "Pending Signature"],
  [Status.PendingScanning, "Pending Scanning"],
  [Status.Signed, "Signed"],
  [Status.Archived, "Archived"],
  [Status.Invalid, "Invalid"]
]);
