/*eslint-disable*/
export class UserModel {
  businessPhones: string[];
  displayName = "";
  givenName = "";
  surname = "";
  userPrincipalName: string;
  preferredLanguage: string;
  mail: string;
}
