/*eslint-disable*/
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { AdminService } from "../services/admin.service";

@Injectable()
export class HomeResolver implements Resolve<any> {
  constructor(private adminService: AdminService) {}

  resolve(): Observable<any> {
    return this.adminService.getNewsBanner().pipe(catchError(() => of({})));
  }
}
