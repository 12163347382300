/*eslint-disable*/
import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, of, forkJoin } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { AdminService } from "../services/admin.service";
import { LovTypeService } from "../services/lov-type.service";

@Injectable()
export class AdminResolver implements Resolve<any> {
  constructor(private adminService: AdminService,
    private lovTypeService: LovTypeService) { }

  resolve(): Observable<any> {
    const newsBanner = this.adminService.getNewsBanner().pipe(catchError(() => of("")));
    const lovTypes = this.lovTypeService.getLovTypes().pipe(catchError(() => of({})));

    return forkJoin(newsBanner, lovTypes).pipe(
      map(allResponses => {
        return {
          newsBanner: allResponses[0],
          lovTypes: allResponses[1]
        };
      })
    );
  }
}
