/*eslint-disable*/
import { Component, OnInit, Input } from "@angular/core";
import { TemplateMappingModel } from "../../shared/models/template-mapping.model";
import { SignerModel } from "../../shared/models/signer.model";
import { DocuSignService, DocuSignToken } from "../../core/services/docusign.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { MsalService } from "@azure/msal-angular";
import { Store, select } from "@ngrx/store";
import { AuthState, selectorAuth, UserModel, UserService } from "../../core";
import { switchMap, takeUntil } from "rxjs/operators";
import { Observable, of, Subject } from "rxjs";

@Component({
  selector: "anms-create-contract",
  templateUrl: "./create-contract.component.html",
  styleUrls: ["./create-contract.component.scss"]
})
export class CreateContractComponent implements OnInit {
  basicInfoValid: boolean;
  contractInfoValid: boolean;
  signersWarning: string;
  ucbSignersWarning: string;

  contractTemplateMappings: TemplateMappingModel[];
  selectedContractTemplate: TemplateMappingModel;

  ucbSigners: SignerModel[];
  thirdPartySigners: SignerModel[];
  ucbCCRecipients: SignerModel[];
  thirdPartyCCRecipients: SignerModel[];
  contractMetadata: any;

  loading: boolean;
  isAuthenticated: any;
  userName: string;
  public user$: Observable<UserModel>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(public docuSignService: DocuSignService,
    private adalService: MsalService,
    private router: Router,
    private store: Store<any>,
    private userService: UserService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.checkDocuSignTokenAndAuthenticateIfNeeded();
    this.ucbSigners = new Array();
    this.thirdPartySigners = new Array();
    this.ucbCCRecipients = new Array();
    this.thirdPartyCCRecipients = new Array();
  }

  checkDocuSignTokenAndAuthenticateIfNeeded() {
    if (this.docuSignService.cachedTokenIsValid()) {
      return;
    } else {
      if (this.docuSignService.getRefreshToken()) {
        this.docuSignService.refreshToken().subscribe((response: DocuSignToken) => {
          this.docuSignService.cacheToken(response);
          return;
        });
      } else {

        // this should be a redirect form DocuSign login; get the token, validate it and cache it
        if (this.route.snapshot.queryParams.code) {

          const token = this.docuSignService.getTokenDetails(this.route.snapshot.queryParams.code)
            .subscribe((response: DocuSignToken) => {
              response.state = this.route.snapshot.queryParams.state;
              this.docuSignService.cacheToken(response);
              if (response.access_token) {
                return;
              } else {
                this.snackBar.open("Something went wrong during your authentication to DocuSign. Please try again later.",
                  null,
                  {
                    panelClass: "snack-error",
                    duration: 4000
                  });
                setTimeout(() => {
                  this.router.navigate([""]);
                }, 4000);
              }
            });
        }
        // there is no valid DocuSign token cached & this call is not a redirect from DocuSign Login,
        // so we will redirect the user to DocuSign for login
        else {
          this.docuSignService.redirectToDocuSignLogin(window.location.href);
        }
      }
    }
  }

  onContractInfoChanged(event) {
    this.contractInfoValid = event;
  }

  onContractTemplateChanged(event) {
    this.selectedContractTemplate = event;
    this.signersWarning =
      "You must add " +
      this.selectedContractTemplate.numberOfTPSigners +
      " Contract Party Signer(s) to this contract.";
    this.ucbSignersWarning =
      "You must add " +
      this.selectedContractTemplate.numberOfUCBSigners +
      " UCB Signer(s) to this contract.";
  }

  onBasicInfoChanged(event) {
    this.basicInfoValid = event;
  }

  onUcbSignersUpdated(signers) {
    this.ucbSigners = signers;
  }

  onThirdPartySignersUpdated(signers) {
    this.thirdPartySigners = signers;
  }

  onUcbCCRecipientsUpdated(recipients) {
    this.ucbCCRecipients = recipients;
  }

  onThirdPartyCCRecipientsUpdated(recipients) {
    this.thirdPartyCCRecipients = recipients;
  }

  signersAdded() {
    return this.ucbSignersComplete() && this.thirdPartySignersComplete();
  }

  thirdPartySignersComplete() {
    if (!this.selectedContractTemplate) {
      return false;
    }

    return (
      this.thirdPartySigners.length ==
      this.selectedContractTemplate.numberOfTPSigners
    );
  }

  ucbSignersComplete() {
    if (!this.selectedContractTemplate) {
      return false;
    }

    return (
      this.ucbSigners.length ==
      this.selectedContractTemplate.numberOfUCBSigners
    );
  }

  getContractMetadata(data) {
    this.contractMetadata = data;
  }

  createContract() {
    const docuSignContract = this.setupDocuSignContract();
    this.loading = true;
    this.docuSignService
      .createContractFromDocuSignTemplate(docuSignContract)
      .subscribe(docuSignUrl => {
        window.open(docuSignUrl, "_blank");
        this.router.navigate([""]);
      });
  }

  setupDocuSignContract(): any {
    this.user$ = this.store
      .pipe(
        select(selectorAuth),
        takeUntil(this.unsubscribe$),
        switchMap((auth: AuthState) => {
          this.isAuthenticated = auth.isAuthenticated;
          if (this.isAuthenticated) {
            return this.userService.get();
          }

          return of(new UserModel());
        })
      );
    this.user$.subscribe(res => this.userName = res.givenName + " " + res.surname);
    const contractInfo = {
      title: this.contractMetadata.title,
      isDigital: true,
      loggedBy: this.userName,
      amendment: this.contractMetadata.amendment,
      ucbEntityId: this.contractMetadata.ucbEntity.id,
      thirdParty: this.contractMetadata.thirdParty,
      originatorId: this.contractMetadata.originator.id,
      keywords: this.contractMetadata.keywords,
      signatureDate: this.contractMetadata.signatureDate,
      effectiveDate: this.contractMetadata.effectiveDate,
      expiryDate: this.contractMetadata.expiryDate
    };

    return {
      contract: contractInfo,
      templateMappingId: this.selectedContractTemplate.id,
      ucbSigners: this.ucbSigners,
      thirdPartySigners: this.thirdPartySigners,
      ucbCCRecipients: this.ucbCCRecipients,
      thirdPartyCCRecipients: this.thirdPartyCCRecipients
    };
  }
}
