/*eslint-disable*/
import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, switchMap, finalize } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { of } from "rxjs";
import { ConfigService } from "../../core/config/config.service";

@Component({
  selector: "aad-user-autocomplete",
  templateUrl: "./aad-user-autocomplete.component.html",
  styleUrls: ["./aad-user-autocomplete.component.scss"]
})
export class AadUserAutocompleteComponent implements OnInit {
  @Input() inputClass: string;
  @Input() placeholder: string;
  @Input() required: boolean;
  @Output() userSelected = new EventEmitter<any>();

  aadInput = new UntypedFormControl();
  filteredUsers = [];
  searchingUsers = false;
  selectedUser: any;
  graphUrl: string;

  constructor(private http: HttpClient, private configService: ConfigService) { }

  ngOnInit() {
    this.aadInput.valueChanges
      .pipe(
        debounceTime(300),
        switchMap(value => {
          this.filteredUsers = [];
          if (value && value.length >= 3) {
            this.searchingUsers = true;
            return this.http.get(this.graphUrl + this.getQueryString(value));
          }
          return of({ value: [] });
        })
      )
      .subscribe(
        (result: any) => {
          this.filteredUsers = result.value;
          this.searchingUsers = false;
        },
        error => (this.searchingUsers = false)
      );

    this.graphUrl = this.configService.get().endpoints.graph.url;
  }

  onOptionSelected($event) {
    this.selectedUser = $event.option.value;
    this.userSelected.emit(this.selectedUser);
  }

  getUserDisplayName(user) {
    return user ? user.displayName : null;
  }

  checkSelectedUser() {
    if (!this.selectedUser || this.selectedUser !== this.aadInput.value) {
      this.aadInput.setValue(null);
      this.selectedUser = null;
      this.userSelected.emit(null);
    }
  }

  private getQueryString(value: string) {
    value = value.trim();
    let queryString = "/users?$filter=";

    if (value.indexOf(" ") >= 0) {
      const firstSpaceIndex = value.indexOf(" ");
      const lastSpaceIndex = value.lastIndexOf(" ");

      const givenName = value.substring(0, firstSpaceIndex);
      const surname = value.substring(firstSpaceIndex + 1);

      const alternateGivenName = value.substring(0, lastSpaceIndex);
      const alternateSurname = value.substring(lastSpaceIndex + 1);

      queryString += this.getUserSearchQuery(givenName, surname);
      queryString += " or ";
      queryString += this.getUserSearchQuery(
        alternateGivenName,
        alternateSurname
      );
    } else {
      queryString += `startswith(displayName, '${value}') or startswith(givenName, '${value}') or startswith(surname, '${value}')`;
    }
    return queryString;
  }

  private getUserSearchQuery(givenName: string, surname: string) {
    return `((startswith(givenName, '${givenName}') and startswith(surname, '${surname}')) or
     (startswith(givenName, '${surname}') and startswith(surname, '${givenName}')))`;
  }
}
