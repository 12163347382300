/*eslint-disable*/
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";
import { HttpClient } from "@angular/common/http";
import { NewsBanner } from "../../shared/models/news-banner.model";

@Injectable()
export class AdminService {
  apiUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.apiUrl = this.config.get().apiUrl;
  }

  getNewsBanner(): Observable<NewsBanner[]> {
    const uri = this.apiUrl + "/admin/get-news-banner";
    return this.http.get<NewsBanner[]>(uri);
  }

  updateDescription(newsBanner: NewsBanner): Observable<any> {
    const uri = this.apiUrl + `/admin/update-news-banner`;
    return this.http.post<any>(uri, newsBanner);
  }

  updateNews(newsBanner: NewsBanner[]): Observable<any> {
    const uri = this.apiUrl + `/admin/update-news-banner-list`;
    return this.http.post<any>(uri, newsBanner);
  }
}
