/*eslint-disable*/
import { ContractService } from "./../../core/services/contract.service";
import { ContractCoversheet } from "@app/shared/models/contract-coversheet.model";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { saveAs } from "file-saver";

@Component({
  selector: "anms-coversheet",
  templateUrl: "./coversheet.component.html",
  styleUrls: ["./coversheet.component.scss"]
})
export class CoversheetComponent implements OnInit {
  coversheet: ContractCoversheet;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contractService: ContractService
  ) {}

  ngOnInit() {
    this.coversheet = this.route.snapshot.data.result;
  }

  public printToPdf(title) {
    this.contractService
      .getCoversheetPdf(this.route.snapshot.paramMap.get("id"))
      .subscribe(blob => {
        const file = new Blob([blob], { type: "application/pdf" });
        saveAs(file, title + ".pdf");
      });
  }

  onClose() {
    this.router.navigate(["/send"]);
  }
}
