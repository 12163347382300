/*eslint-disable*/
import { Component, OnInit, Input, ViewChild, ViewChildren, QueryList, ElementRef, AfterViewInit } from "@angular/core";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { MatSort } from "@angular/material/sort";
import { SearchFilter } from "../models/search-filter.model";
import { MaterialGridDataSource } from "./material-grid.data-source";
import { fromEvent, merge, Subject } from "rxjs";
import { map, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { ISearchService } from "./search-service.interface";
import { Lov } from "../models/lov.model";
import { ContractService } from "../../core/services/contract.service";
import { StatusLabel } from "../../shared/enums/status.enum";

@Component({
  selector: "anms-material-grid",
  templateUrl: "./material-grid.component.html",
  styleUrls: ["./material-grid.component.scss"]
})
export class MaterialGridComponent implements OnInit, AfterViewInit {

  @Input() columns: [{ name: string; displayName: string; isFilterable: boolean; isSortable: boolean; cellTemplate: ElementRef }];
  @Input() displayedColumns: string[];
  @Input() searchService: ISearchService;
  @Input() sortingConfig: { matSortActive: string; matSortDirection: string; matSortDisableClear: boolean };
  @Input() filterConfig: { column: string; value: string };

  dataSource: MaterialGridDataSource;

  loading: boolean;
  ucbEntityOptions: Lov[];
  originatorOptions: Lov[];
  contractTypeOptions: Lov[];
  statusList: string[] = [];
  status = "Filter";
  originator = "Filter";
  contractType = "Filter";
  ucbEntity = "Filter";
  searchFilter: SearchFilter = {
    filters: [],
    pageIndex: 0,
    pageSize: 10,
    sortColumn: "",
    sortDirection: ""
  };
  clear = new Subject<boolean>();
  ddmmyyyy = "DD-MM-YYYY";

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  get totalRecords() {
    return this.dataSource.totalRecords;
  }

  filterValue(columnName) {
    if (this.searchFilter.filters) {
      const currentFilter = this.searchFilter.filters.filter(filter => filter.columnName == columnName)[0];
      return currentFilter ? currentFilter.filterValue : "";
    } else {
return "";
}
  }

  constructor(public contractService: ContractService, ) {
  }

  ngOnInit() {
    this.loadData();
    this.dataSource = new MaterialGridDataSource(this.searchService);
    this.dataSource.loading$.subscribe(loading => this.loading = loading);
    if (this.filterConfig != null) {
      this.searchFilter.filters.push({
        columnName: this.filterConfig.column,
        filterValue: this.filterConfig.value
      });
    }
  }

  private loadData() {
    this.contractService.getLovs("UCB Entity").subscribe(result => {
      this.ucbEntityOptions = result;
    });
    this.contractService.getLovs("Originator").subscribe(result => {
      this.originatorOptions = result;
    });
    this.contractService.getLovs("Contract Type").subscribe(result => {
      this.contractTypeOptions = result;
    });

    for (const value of StatusLabel.values()) {
      this.statusList.push(value);
    }
  }

  ngAfterViewInit() {
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => this.search())
      )
      .subscribe();

    setTimeout(() => {
      this.search();
    }, 100);
  }

  filter(columnName, event) {
    let searchText;

    if (event.source == undefined) {
searchText = event;
} else if (event.source != undefined) {
      searchText = (event.value == "Filter" ? "" : event.value);
    }
    const columnFilter = this.searchFilter.filters.filter(function(item) {
      return item.columnName == columnName;
    })[0];
    if (columnFilter) {
      columnFilter.filterValue = searchText;
    } else {
      this.searchFilter.filters.push({ columnName, filterValue: searchText });
    }

    this.search();
    this.paginator.firstPage();
  }

  search() {
    this.searchFilter.pageIndex = this.paginator.pageIndex;
    this.searchFilter.pageSize = this.paginator.pageSize;
    this.searchFilter.sortDirection = this.sort.direction;
    this.searchFilter.sortColumn = this.sort.active;

    this.dataSource.search(this.searchFilter);
  }

  clearFilters() {
    this.clear.next(true);
    this.searchFilter.filters = [];
    this.status = "Filter";
    this.originator = "Filter";
    this.contractType = "Filter";
    this.ucbEntity = "Filter";
    this.search();
  }
}
