/*eslint-disable*/
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpErrorResponse
} from "@angular/common/http";
import { catchError, last, map, tap } from "rxjs/operators";
import { of } from "rxjs";
import { FileUploadModel } from "../models/file-upload.model";
import { ConfigService } from "../../core/config/config.service";
import { Guid } from "guid-typescript";

@Component({
  selector: "anms-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"]
})
export class UploadFileComponent implements OnInit {
  /** Link text */
  @Input() text: string;
  /** upload button text */
  @Input() upload: string;
  /** Contract used in form which will be sent in HTTP request. */
  @Input() formData: FormData;
  /** Target URL for file uploading. */
  @Input() target: string;
  /** File extension that accepted, same as 'accept' of <input type="file" />.
   * Default value: PDF & Word documents
   */
  @Input() accept: string;
  /** Form validation check */
  @Input() formValid: boolean;
  /** Maximum number of files.*/
  @Input() limit: number;
  /** Maximum file size allowed. Default value: 25MB*/
  @Input() fileSizeLimit: number;
  /** Allow you to add handler after its completion. Bubble up response text from remote. */
  @Output() complete = new EventEmitter<string>();
  /** Triggers event on click of'Back' button */
  @Output() isBackButtonClicked = new EventEmitter<boolean>();
  /** Disable back button */
  @Input() hasBackButton: boolean;

  file: FileUploadModel;
  files: FileUploadModel[] = [];
  validationErrors: string[] = [];
  showUploadButton = true;
  disableBackButton = false;

  constructor(private http: HttpClient,
    private config: ConfigService) {
  }

  ngOnInit() {
    // by default, file size limit is 25MB
    if (!this.fileSizeLimit) {
      this.fileSizeLimit = 26214400;
    }
    if (!this.accept) {
      this.accept = ".doc,.docx,.pdf";
    }
    if (!this.limit) {
      this.limit = 1;
    }
    if (this.target) {
      this.target = this.config.get().apiUrl + this.target;
    }
  }

  onClick() {
    this.resetForm();
    const fileUpload = document.getElementById(
      "fileUpload"
    ) as HTMLInputElement;

    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      if (file == undefined || file == null) {
return;
}
      this.file = {
        data: file,
        state: "in",
        inProgress: false,
        progress: 0,
        canRetry: false,
        canCancel: true
      };
      if (this.selectedFileIsValid()) {
        this.complete.emit(null);
        this.addFile(file);
      } else {
        this.complete.emit(null);
      }
    };
    if (this.validationErrors.length === 0) {
      fileUpload.click();
    }
  }

  selectedFileIsValid() {
    this.validationErrors.splice(0);
    return this.fileSizeIsValid() && this.fileTypeIsValid();
  }

  fileTypeIsValid() {
    if (!this.accept) {
      return true;
    }

    const fileName = this.file.data.name;
    const fileType = fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
    const acceptedFileTypes = this.accept.toLowerCase().split(",");

    if (acceptedFileTypes.indexOf(fileType) === -1) {
      this.validationErrors.push(
        "This file type is not supported. Supported file types are: " +
        this.accept
      );
      return false;
    }
    return true;
  }

  fileSizeIsValid() {
    if (this.file.data.size > this.fileSizeLimit) {
      this.validationErrors.push(
        "Files size cannot exceed" +
        this.fileSizeLimit / 1048576 + " MB."
      );
      return false;
    }
    return true;
  }

  cancelFile() {
    this.file.sub.unsubscribe();
    this.showUploadButton = true;
    this.disableBackButton = false;
    this.formData.delete("files");
  }

  removeFile(id) {
    this.files = this.files.filter(item => item.id !== id);
    if (!this.validateFileSize()) {
this.validationErrors.splice(0);
}
  }

  addFile(file) {
    this.file = {
      data: file,
      state: "in",
      inProgress: false,
      progress: 0,
      canRetry: false,
      canCancel: false,
      id: Guid.create()
    };

    if (this.validateTotalSize() && this.validateTotalFiles()) {
this.files.push(this.file);
} else {
      this.complete.emit(null);
    }
  }

  validateTotalSize() {
    let sum = this.files.reduce((acc, cur) => acc + cur.data.size, 0);
    sum = sum + this.file.data.size;
    if (sum > this.fileSizeLimit) {
      this.validationErrors.push(
        "Files size cannot exceed " +
        this.fileSizeLimit / 1048576 + " MB."
      );
      return false;
    }
    return true;
  }

  validateTotalFiles() {
    if (this.files.length >= this.limit) {
      this.validationErrors.push(
        "You can upload maximum " + this.limit + " file(s)"
      );
      return false;
    }
    return true;
  }

  addFormData() {
    this.files.forEach(item => {
      this.formData.append("files", item.data, item.data.name);
    });
  }

  uploadFile() {
    this.addFormData();
    this.showUploadButton = false;
    this.disableBackButton = true;
    this.validationErrors.splice(0);

    let newTab = null;
    if (this.text === "Upload Contract") {
newTab = window.open("", "_blank");
}

    const req = new HttpRequest("POST", this.target, this.formData, {
      reportProgress: true
    });

    this.file.inProgress = true;
    this.file.sub = this.http
      .request(req)
      .pipe(
        map(event => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              this.file.progress = Math.round(
                (event.loaded * 100) / event.total
              );
              break;
            case HttpEventType.Response:
              return event;
          }
        }),
        tap(message => { }),
        last(),
        catchError((error: HttpErrorResponse) => {
          this.showUploadButton = true;
          this.file.inProgress = false;
          this.file.canRetry = true;
          return of(`${this.file.data.name} upload failed.`);
        })
      )
      .subscribe((event: any) => {
        this.showUploadButton = true;
        if (this.text === "Upload Contract") {
newTab.location.href = event.body;
}
        this.complete.emit(event.body);
      });
  }

  resetForm() {
    this.validationErrors.splice(0);
    this.showUploadButton = true;
    const fileUpload = document.getElementById(
      "fileUpload"
    ) as HTMLInputElement;
    fileUpload.value = "";
  }

  goback() {
    this.isBackButtonClicked.emit(true);
  }

  validateFileSize() {
    const sum = this.files.reduce((acc, cur) => acc + cur.data.size, 0);

    if (sum > this.fileSizeLimit) {
return true;
} else {
return false;
}
  }
}
