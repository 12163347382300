/*eslint-disable*/
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";

import { ConfigService } from "../config/config.service";
import { ClaimGuard } from "./claim.guard";
import { MsalService } from "@azure/msal-angular";

@Injectable()
export class RoleGuard extends ClaimGuard {
  constructor(authService: MsalService, configService: ConfigService) {
    super(authService, configService);
  }

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    next.data = {
      claims: {
        roles: next.data.roles
      }
    };

    return super.canActivate(next, state);
  }
}
