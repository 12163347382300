/*eslint-disable*/
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ConfigService } from "../config/config.service";
import { HttpClient } from "@angular/common/http";
import { DsEvent } from "../../shared/models/ds-event.model";
import { CacheService } from "./cache.service";
import { Guid } from "guid-typescript";
import { UserService } from "./user.service";

@Injectable()
export class DocuSignService {

  apiUrl: string;
  constructor(private config: ConfigService,
    private http: HttpClient,
    private cacheService: CacheService, private userService: UserService) {
    this.apiUrl = this.config.get().apiUrl;
  }

  createContractFromDocuSignTemplate(contract: any): Observable<any> {
    const uri = this.apiUrl + `/ds/create-contract-from-template`;
    contract.DocuSignAuthToken = this.cacheService.get("dsToken");
    return this.http.post<string>(uri, contract);
  }

  handleDocuSignEvent(dsEvent: any): Observable<any> {
    const uri = this.apiUrl + `/ds/event-handler`;
    return this.http.post<DsEvent>(uri, dsEvent);
  }

  getTokenDetails(code: string): Observable<any> {
    const token: DocuSignToken = { code };
    const uri = this.apiUrl + `/ds/token`;
    return this.http.post<DocuSignToken>(uri, token);
  }

  refreshToken(): Observable<any> {
    const token: DocuSignToken = { refresh_token: this.cacheService.get("dsRefreshToken") };
    const uri = this.apiUrl + `/ds/token`;
    return this.http.post<DocuSignToken>(uri, token);
  }

  cachedTokenIsValid() {
    const dsToken: DocuSignToken = {
      access_token: this.cacheService.get("dsToken"),
      expires_in: this.cacheService.get("dsTokenExpiresAt"),
      state: this.cacheService.get("dsState")
    };

    return this.tokenIsValid(dsToken);
  }

  tokenIsValid(dsToken: DocuSignToken) {

    if (!dsToken.access_token || !dsToken.expires_in || !dsToken.state) {
      return false;
    }

    if (dsToken.state != this.cacheService.get("dsState")) {
      return false;
    }

    return dsToken.expires_in > new Date().getTime();
  }

  cacheToken(dsToken: DocuSignToken) {
    const dt = new Date();
    dt.setSeconds(dt.getSeconds() + dsToken.expires_in);
    this.cacheService.set("dsToken", dsToken.access_token);
    this.cacheService.set("dsTokenExpiresAt", dt.getTime());
    // this.cacheService.set('dsState', dsToken.state);
    this.cacheService.set("dsRefreshToken", dsToken.refresh_token);
  }

  redirectToDocuSignLogin(callbackUri: string) {
    // cache 'state', in order to compare it with DocuSign return request
    // to verify callback's authenticity
    const state = Guid.create();
    this.cacheService.set("dsState", state.toString());

    this.userService.getEmail().subscribe((email) => {
      const authStartUrl = this.config.get().docuSign.oAuthEndpoint +
        "&response_type=code" +
        "&scope=signature impersonation" +
        "&client_id=" + this.config.get().docuSign.clientId +
        "&state=" + state +
        "&redirect_uri=" + callbackUri +
        "&login_hint=" + email;

      window.location.href = authStartUrl;
    });
  }

  getCachedToken() {
    return this.cacheService.get("dsToken");
  }

  getRefreshToken() {
    return this.cacheService.get("dsRefreshToken");
  }

  removeToken() {
    return this.cacheService.remove("dsToken");
  }

}

export class DocuSignToken {
  code?: string;
  access_token?: string;
  token_type?: string;
  expires_in?: number;
  refresh_token?: string;
  state?: string;
}
