/*eslint-disable*/
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacyChipsModule as MatChipsModule } from "@angular/material/legacy-chips";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTooltipModule as MatTooltipModule } from "@angular/material/legacy-tooltip";
import { MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/legacy-snack-bar";

import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { MatSortModule } from "@angular/material/sort";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { TranslateModule } from "@ngx-translate/core";
import { ThemeEmitterComponent } from "./theme-emitter/theme-emitter.component";
import { AsyncImageComponent } from "./async-image/async-image.component";
import { MaterialGridComponent } from "./material-grid/material-grid.component";
import { DropdownAutocompleteComponent } from "./dropdown-autocomplete/dropdown-autocomplete.component";
import { UploadFileComponent } from "./upload-file/upload-file.component";
import { HttpClientModule } from "@angular/common/http";
import { ContractMetadataComponent } from "./contract-metadata/contract-metadata.component";
import { AppRoutingModule } from "../app-routing.module";
import { AadUserAutocompleteComponent } from "./aad-user-autocomplete/aad-user-autocomplete.component";
import { SignerComponent } from "./signer/signer.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { ColumnFilterComponent } from "./material-grid/column-filter/column-filter.component";

@NgModule({
    imports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatToolbarModule,
        MatSelectModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatChipsModule,
        MatCardModule,
        MatSidenavModule,
        MatCheckboxModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule
    ],
    declarations: [
        ThemeEmitterComponent,
        DropdownAutocompleteComponent,
        AsyncImageComponent,
        AadUserAutocompleteComponent,
        SignerComponent,
        UploadFileComponent,
        ContractMetadataComponent,
        MaterialGridComponent,
        ConfirmationDialogComponent,
        ColumnFilterComponent
    ],
    exports: [
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        AppRoutingModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatMenuModule,
        MatTabsModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatCardModule,
        MatSidenavModule,
        MatListModule,
        MatSelectModule,
        MatToolbarModule,
        MatIconModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatDialogModule,
        MatPaginatorModule,
        MatSortModule,
        MatTableModule,
        MatAutocompleteModule,
        HttpClientModule,
        MatProgressBarModule,
        ThemeEmitterComponent,
        AsyncImageComponent,
        DropdownAutocompleteComponent,
        MatRadioModule,
        MatDatepickerModule,
        MatNativeDateModule,
        AadUserAutocompleteComponent,
        SignerComponent,
        UploadFileComponent,
        ContractMetadataComponent,
        MaterialGridComponent
    ],
    providers: [
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: "right", duration: 1500 } }
    ]
})
export class SharedModule { }
