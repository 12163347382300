/*eslint-disable*/
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../config/config.service";
import { ISearchService } from "../../shared/material-grid/search-service.interface";
import { SearchFilter } from "../../shared/models/search-filter.model";
import { Observable } from "rxjs";
import { SearchResultsDataSource } from "../../shared/models/search-results-data-source.model";
import { Lov } from "../../shared/models/lov.model";
import { Guid } from "guid-typescript";

@Injectable()
export class LovService implements ISearchService {

  apiUrl: string;

  constructor(private config: ConfigService, private http: HttpClient) {
    this.apiUrl = this.config.get().apiUrl;
  }

  search(searchFilter: SearchFilter): Observable<any> {
    const uri = this.apiUrl + "/lov/search";
    return this.http.post<SearchResultsDataSource<Lov>>(uri, searchFilter);
  }

  create(lov: Lov): Observable<boolean> {
    const uri = this.apiUrl + "/lov/create";
    return this.http.post<boolean>(uri, lov);
  }

  update(lov: Lov): Observable<boolean> {
    const uri = this.apiUrl + "/lov/update";
    return this.http.post<boolean>(uri, lov);
  }

  updateVisibility(isVisible: boolean, lovId: Guid): Observable<boolean> {
    const uri = this.apiUrl + "/lov/update-visibility";
    return this.http.post<boolean>(uri, { isVisible, id: lovId });
  }
}
