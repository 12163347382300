/*eslint-disable*/
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Component, OnInit, ViewChild, AfterViewInit } from "@angular/core";
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { saveAs } from "file-saver";
import { ContractService } from "../../core/services/contract.service";
import { MaterialGridComponent } from "../../shared/material-grid/material-grid.component";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { Status, StatusLabel } from "../../shared/enums/status.enum";
import { DomSanitizer } from "@angular/platform-browser";
import { ConfigService } from "../../core/config/config.service";
import { Guid } from "guid-typescript";
import { UserService } from "../../core";

@Component({
  templateUrl: "./my-contracts.component.html",
  styleUrls: ["./my-contracts.component.scss"]
})
export class MyContractsComponent implements OnInit, AfterViewInit {

  @ViewChild("dateTemplate", { static: true }) dateTemplate;
  @ViewChild("documentLinkTemplate", { static: true }) documentLinkTemplate;
  @ViewChild("statusTemplate", { static: true }) statusTemplate;
  @ViewChild("processFailureTemplate", { static: true }) processFailureTemplate;
  @ViewChild(MaterialGridComponent, { static: true }) grid;

  isAdmin = false;

  displayedColumns = ["thirdParty", "ucbEntity", "contractType", "status",
    "originator", "loggedDate", "documentLink"];
  initialColumns = [...this.displayedColumns];

  sortingConfig = {
    matSortActive: "loggedDate",
    matSortDirection: "desc",
    matSortDisableClear: true
  };

  filterConfig = {
    column: "",
    value: "",
  };

  columns = [
    {
      name: "contractId",
      displayName: "Contract ID"
    },
    {
      name: "title",
      displayName: "Title"
    },
    {
      name: "status",
      displayName: "Status",
      isFilterable: true,
      isDropdown: true
    },
    {
      name: "thirdParty",
      displayName: "Contract Party",
      isFilterable: true
    },
    {
      name: "originator",
      displayName: "Originator",
      isFilterable: true,
      isDropdown: true
    },
    {
      name: "contractType",
      displayName: "Contract Type",
      isFilterable: true,
      isDropdown: true
    },
    {
      name: "loggedDate",
      displayName: "Logged Date",
      isFilterable: true,
      isSortable: true
    },
    {
      name: "documentLink",
      displayName: "Document Link",
      cellTemplate: null
    },
    {
      name: "ucbEntity",
      displayName: "UCB Entity",
      isFilterable: true,
      isDropdown: true
    },
    {
      name: "signatureDate",
      displayName: "Signature Date"
    },
    {
      name: "effectiveDate",
      displayName: "Effective Date"
    },
    {
      name: "expiryDate",
      displayName: "Expiry Date"
    },
    {
      name: "loggedBy",
      displayName: "Logged By"
    },
    {
      name: "processFailure",
      displayName: "Process Failure",
      isFilterable: true
    },
    {
      name: "contractRepository",
      displayName: "Contract Repository"
    }
  ];

  columnOptions = [
    { name: "contractId", displayName: "Contract ID" }, { name: "title", displayName: "Title" },
    { name: "signatureDate", displayName: "Signature Date" }, { name: "effectiveDate", displayName: "Effective Date" },
    { name: "expiryDate", displayName: "Expiry Date" }, { name: "loggedBy", displayName: "Logged By" },
    { name: "contractRepository", displayName: "Contract Repository" }
  ];

  selectedView = "inProgress";
  selectedExtraColumns = [];
  status = Status;
  statusLabel = StatusLabel;
  confirmationDialogRef: MatDialogRef<ConfirmationDialogComponent>;
  contractShareDocUrl: string;
  contractRoomDocUrl: string;

  get filters() {
    return this.grid.searchFilter;
  }

  constructor(public contractService: ContractService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer,
    private configService: ConfigService,
    private userService: UserService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.isAdmin = this.route.snapshot.data.result;
    const parameter = this.route.snapshot.paramMap.get("failed");
    if (parameter != null) {
      this.filterConfig.column = "processFailure";
      this.filterConfig.value = parameter;
    }
    if (this.isAdmin) {
      this.displayedColumns.splice(this.displayedColumns.length - 1, 0, "processFailure");
    }
  }


  ngAfterViewInit() {
    ["loggedDate", "signatureDate", "effectiveDate", "expiryDate"].forEach(column => {
      const dateColumn = this.columns.filter(function(item) {
        return item.name == column;
      })[0];
      dateColumn.cellTemplate = this.dateTemplate;
    });

    const documentColumn = this.columns.filter(function(item) {
      return item.name == "documentLink";
    })[0];
    documentColumn.cellTemplate = this.documentLinkTemplate;

    const statusColumn = this.columns.filter(function(item) {
      return item.name == "status";
    })[0];
    statusColumn.cellTemplate = this.statusTemplate;

    const processFailureColumn = this.columns.filter(function(item) {
      return item.name == "processFailure";
    })[0];
    processFailureColumn.cellTemplate = this.processFailureTemplate;

    this.grid.searchFilter.view = this.selectedView;
  }

  exportContracts() {
    this.contractService.export(this.filters).subscribe(file => {
      const blob = new Blob([file], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const fileName = "Contracts list " + this.datePipe.transform(new Date(), "dd-MM-yyyy") + ".xlsx";
      saveAs(blob, fileName);
    });
  }

  onColumChange(event) {
    this.selectedExtraColumns = this.columns.filter(function(item) {
      return event.value.some(x => x == item.name);
    });
    this.displayedColumns = Object.assign([], this.initialColumns);

    if (this.isAdmin) {
      this.displayedColumns.splice(this.displayedColumns.length - 1, 0, "processFailure");
    }
    this.displayedColumns.splice(this.displayedColumns.length - 1, 0, ...event.value);
  }

  onViewChange(event) {
    this.grid.searchFilter.view = event.value;
    this.grid.search();
    this.contractShareDocUrl = this.configService.get().ContractShareDocUrl;
    this.contractRoomDocUrl = this.configService.get().ContractRoomDocUrl;
  }

  clearFilters() {
    this.grid.clearFilters();
  }

  openInvalidateConfirmationDialog(contract) {
    let dialogMessage: String;
    if (contract.status === this.status.Created || contract.status === this.status.PendingSignature) {
      dialogMessage = "To delete this entry, please go to DocuSign. You can access it by clicking on the document link.";
      this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "600px",
        data: {
          dialogTitle: "Invalidate",
          dialogMessage,
          showConfirmButton: false,
          cancelButtonText: "Return"
        }
      });
    } else if (contract.status === this.status.PendingScanning) {
      dialogMessage = "Are you sure you want to invalidate this entry? This can not be undone.";
      this.confirmationDialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: "600px",
        data: {
          dialogTitle: "Invalidate",
          dialogMessage,
          showConfirmButton: true
        }
      });

      this.confirmationDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.contractService.invalidateContract(contract.id).subscribe(result => {
            this.grid.search();
          });
        }
      });
    } else {
this.grid.search();
}
  }

  canInvalidate(status) {
    return status == this.status.Created || status == this.status.PendingSignature || status == this.status.PendingScanning;
  }

  getDocuSignEnvelopeUrl(envelopeId) {
    return this.sanitizer.bypassSecurityTrustUrl(this.configService.get().docuSign.envelopeDetailsUri + envelopeId);
  }

  isEmpty(value) {
    return value == Guid.EMPTY;
  }
}
