/*eslint-disable*/
import { MsalService } from "@azure/msal-angular";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable, of, throwError } from "rxjs";
import { ConfigService } from "../config/config.service";
import { map } from "rxjs/operators";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";

@Injectable()
export class ClaimGuard implements CanActivate {

  constructor(
    private authService: MsalService,
    private configService: ConfigService

  ) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
  try {
      const activeAccount = this.authService.instance.getAllAccounts();

      if (!activeAccount && activeAccount.length > 0) {
        const endpoint = this.configService.get().apiUrl;
        const scopes = this.configService.get().msalConfig.protectedResources[endpoint];
        const requestObj = {
          scopes,
        };

        return this.authService.acquireTokenSilent(requestObj)
          .pipe(map((result: AuthenticationResult) => {
            if (!result.account) {
              return false;
            }
            return this.checkUserRoles(result.account, next.data.claims.roles);
          }));
      }
      return of(this.checkUserRoles(activeAccount[0], next.data.claims.roles));
  } catch (error) {
     console.log(error);
     this.authService.logout();
     throw new Error("Valid token not returned");

  }
}

  private checkUserRoles(activeAccount: AccountInfo, requiredRoles: string[]): boolean {
    const userHasRole = (activeAccount.idTokenClaims as any).roles
      .some((userRole: string) => requiredRoles.includes(userRole));
    return userHasRole;
  }
}
