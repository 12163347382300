/*eslint-disable*/
import { Validators, UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from "@angular/forms";
import { Component, OnInit, Input } from "@angular/core";
import { AdminService } from "../../../core/services/admin.service";
import { NewsBanner } from "../../../shared/models/news-banner.model";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { Guid } from "guid-typescript";

@Component({
  selector: "news-banner",
  templateUrl: "./news-banner.component.html",
  styleUrls: ["./news-banner.component.scss"]
})
export class NewsBannerComponent implements OnInit {
  @Input() newsBanner: NewsBanner[];
  newsForm: UntypedFormGroup;

  constructor(private adminService: AdminService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder) { }

  isChanged = false;

  ngOnInit() {

    this.newsForm = this.fb.group({
      news: this.fb.array([])
    });

    this.newsBanner.forEach((value) => {
      this.addNews(value);
    });

  }

  addNews(value) {
    (<UntypedFormArray>this.newsForm.get("news")).push(this.fb.group({
      id: [value ? value.id : Guid.EMPTY],
      description: [value ? value.description : "",
      [Validators.required, Validators.maxLength(250)]],
      order: [value ? value.order : "", Validators.compose([Validators.required, Validators.min(1), Validators.max(5)])]
    }));
  }

  get news() {
    return (<UntypedFormArray>this.newsForm.get("news")).controls;
  }

  removeNews(i) {
    (<UntypedFormArray>this.newsForm.get("news")).removeAt(i);
    this.isChanged = true;
  }

  onKeyup(i) {
    const newDescr = this.newsForm.get("news")["controls"][i].controls["description"].value.trim();
    const descr = this.newsBanner[i].description;
    if (newDescr.length > 0 && newDescr !== descr) {
      this.isChanged = true;
    } else if (newDescr.length == 0) {
      this.isChanged = false;
    }
  }

  reset(i) {
    this.newsForm.get("news")["controls"][i].controls["description"]
      .setValue(this.newsForm.get("news")["controls"][i].controls["description"].value.trim());
  }

  onOrderKeyup(event, i) {
    const order = this.newsForm.get("news")["controls"][i].controls["order"].value;
    const str = event.key.match(/[^0-9]*/g);
    if (str[0].length == 0) {
      this.isChanged = true;
    }
    if (order !== Math.round(order)) {
      this.isChanged = false;
    }
  }

  apply() {
    this.newsBanner = [];
    this.newsForm.value.news.forEach((value) => {
      this.newsBanner.push(value);
    });

    this.adminService
      .updateNews(this.newsBanner)
      .subscribe(result => {
        this.isChanged = false;
        this.showNotification(result);
      });
  }

  private showNotification(result) {
    if (result) {
this.snackBar.open("Update successful", null, { panelClass: "snack-success" });
} else {
this.snackBar.open("Update unsuccessful", null, { panelClass: "snack-error" });
}
  }
}
