/*eslint-disable*/
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { ConfigService } from "../config/config.service";
import { UserService } from "../services/user.service";

@Injectable()
export class UserResolver implements Resolve<any> {
    isAdmin: boolean;
    constructor(private configService: ConfigService, private userService: UserService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.userService
            .hasRole(this.configService.get().adminRoleName).toPromise()
            .then(response => {
                return response ? true : false;
            });
    }

}
