/*eslint-disable*/
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Guid } from "guid-typescript";
import { SignerModel } from "../models/signer.model";
import { noSpaceValidator } from "../validators/custom.validator";

@Component({
  selector: "anms-signer",
  templateUrl: "./signer.component.html",
  styleUrls: ["./signer.component.scss"]
})
export class SignerComponent implements OnInit {
  @Input() showUserLookupControl: boolean;
  @Input() isTemplate: boolean;
  @Input() buttonText: string;
  @Input() searchText: string;
  @Input() isOptional: boolean;
  @Output() signersList: EventEmitter<SignerModel[]>;

  signersFormGroup: UntypedFormGroup;
  signers: SignerModel[];
  showForm: boolean;
  showAddSignerForm: boolean;
  isUCBSigner: boolean;
  orderLimit = 1;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.signers = new Array();
    this.signersList = new EventEmitter<SignerModel[]>();
  }

  ngOnInit() {
    this.showForm = false;
    this.showAddSignerForm = false;
    this.getOrderLimit();
    this.initForm();
  }

  initForm() {
    this.signersFormGroup = this.formBuilder.group({
      id: [],
      name: ["", Validators.compose([Validators.required, noSpaceValidator])],
      title: ["", Validators.compose([(this.showUserLookupControl) ? noSpaceValidator : null])],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      order: [
        {
          value: (this.showUserLookupControl) ? this.orderLimit : 1,
          disabled: !this.showUserLookupControl
        },
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(this.orderLimit),
          Validators.pattern("^[1-9][0-9]*\d*$")])]
    });

    if (this.showUserLookupControl) {
      this.signersFormGroup.controls["name"].disable();
      this.signersFormGroup.controls["email"].disable();
      this.isUCBSigner = true;
    } else {
this.isUCBSigner = false;
}

    if (this.isTemplate || this.isOptional) {
      this.signersFormGroup.get("order").setValidators(null);
    }
  }

  toggleSignerForm() {
    this.showForm = !this.showForm;

    if (!this.showUserLookupControl) {
      this.showAddSignerForm = !this.showAddSignerForm;
    }
  }

  onCancelAddSigner() {
    this.showAddSignerForm = false;
    this.showForm = false;
  }

  onAddSigner() {
    if (this.formIsValid()) {
      const signer = new SignerModel();
      signer.id = Guid.create().toString();
      signer.name = this.signersFormGroup.controls["name"].value;
      signer.title = this.signersFormGroup.controls["title"].value;
      signer.email = this.signersFormGroup.controls["email"].value;
      if (!this.isTemplate) {
signer.order = this.signersFormGroup.controls["order"].value;
}

      this.signers.push(signer);
      if (!this.isTemplate) {
this.signers.sort((a, b) => 0 - (a.order > b.order ? -1 : 1));
}
      this.signersList.emit(this.signers);

      this.signersFormGroup.reset();
      this.resetOrderControl();
      this.showForm = false;
      this.showAddSignerForm = false;
    }
  }

  onDeleteSigner(signer) {
    this.signers = this.signers.filter(function(el) {
      return el.id !== signer.id;
    });
    this.signersList.emit(this.signers);
  }

  onUserSelectedFromAD(user) {
    if (user) {
      this.signersFormGroup.controls["name"].setValue(user.displayName);
      this.signersFormGroup.controls["title"].setValue(user.jobTitle);
      this.signersFormGroup.controls["email"].setValue(user.userPrincipalName);
      this.resetOrderControl();
      this.showAddSignerForm = true;
    }
  }

  // when the form is disabled & the controls are filled-in dynamically
  // it returns by default invalid, even if all the controls are valid
  formIsValid() {
    for (const field in this.signersFormGroup.controls) {
      if (this.signersFormGroup.get(field).errors) {
        return false;
      }
    }
    return true;
  }

  resetOrderControl() {
    this.getOrderLimit();
    this.signersFormGroup.controls["order"].reset(
      {
        value: (this.showUserLookupControl) ? this.orderLimit : 1,
        disabled: !this.showUserLookupControl
      });
    this.signersFormGroup.controls["order"].setValidators([
      Validators.required,
      Validators.min(1),
      Validators.max(this.orderLimit),
      Validators.pattern("^[1-9][0-9]*\d*$")]);
  }

  getOrderLimit(): number {
    if (this.showUserLookupControl) {
this.orderLimit = this.signers.length + 2;
}
    return this.orderLimit;
  }
}
