/*eslint-disable*/
import { TemplateMappingModel } from "./../../../../shared/models/template-mapping.model";
import { ContractService } from "./../../../../core/services/contract.service";
import { Component, OnInit, Inject, Optional } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Lov } from "../../../../shared/models/lov.model";
import { noSpaceValidator } from "../../../../shared/validators/custom.validator";

@Component({
  selector: "anms-mapping-template-dialog",
  templateUrl: "./mapping-template-dialog.component.html",
  styleUrls: ["./mapping-template-dialog.component.scss"]
})
export class MappingTemplateDialogComponent implements OnInit {
  action: string;
  local_data: any;

  template: TemplateMappingModel;
  basicInfoGroup: UntypedFormGroup;
  languageGroupOptions: Lov[];
  contractTypeOptions: Lov[];
  languagesLoaded = false;
  contractTypesLoaded = false;
  selectedLanguageGroup: Lov;
  selectedContractType: Lov;

  constructor(
    public dialogRef: MatDialogRef<MappingTemplateDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: TemplateMappingModel,
    public contractService: ContractService,
    private formBuilder: UntypedFormBuilder
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit() {
    this.loadData();
    this.initForm();
  }

  private loadData() {
    this.contractService.getLovs("Language Group").subscribe(result => {
      this.languageGroupOptions = result;
      this.languagesLoaded = true;
    });
    this.contractService.getLovs("Contract Type").subscribe(result => {
      this.contractTypeOptions = result;
      this.contractTypesLoaded = true;
    });
  }
  private initForm() {
    this.basicInfoGroup = this.formBuilder.group({
      contractType: [this.local_data.contractType, Validators.required],
      numberOfTpSigners: [
        this.local_data.numberOfTPSigners,
        Validators.compose([Validators.required, Validators.pattern("^[1-9][0-9]*\d*$")])
      ],
      numberOfUCBSigners: [
        this.local_data.numberOfUCBSigners,
        Validators.compose([Validators.required, Validators.pattern("^[1-9][0-9]*\d*$")])
      ],
      languageGroup: [this.local_data.languageGroup, Validators.required],
      docusignTemplateId: [
        this.local_data.docuSignTemplateId,
        Validators.compose([Validators.required, noSpaceValidator])
      ],
      docusignTemplateName: [
        this.local_data.docuSignTemplateName,
        Validators.compose([Validators.required, noSpaceValidator])
      ],
      active: [!!this.local_data.active ? this.local_data.active : false]
    });
    this.selectedLanguageGroup = this.local_data.languageGroup;
    this.selectedContractType = this.local_data.contractType;
  }

  onLanguageSelected($event) {
    this.basicInfoGroup.controls["languageGroup"].setValue(
      $event ? $event : null
    );
  }

  onContractTypeSelected($event) {
    this.basicInfoGroup.controls["contractType"].setValue(
      $event ? $event : null
    );
  }

  save() {
    const basicInfoJson = {
      id: this.local_data.id,
      docuSignTemplateName: this.basicInfoGroup.controls["docusignTemplateName"]
        .value.trim(),
      numberOfTPSigners: this.basicInfoGroup.controls["numberOfTpSigners"]
        .value,
      numberOfUCBSigners: this.basicInfoGroup.controls["numberOfUCBSigners"]
        .value,
      contractType: this.basicInfoGroup.controls["contractType"].value,
      docuSignTemplateId: this.basicInfoGroup.controls["docusignTemplateId"]
        .value.trim(),
      active: this.basicInfoGroup.controls["active"].value,
      languageGroup: this.basicInfoGroup.controls["languageGroup"].value
    };

    this.dialogRef.close({ event: this.action, data: basicInfoJson });
  }

  closeDialog() {
    this.dialogRef.close({ event: "Cancel" });
  }
}
