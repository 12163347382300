/*eslint-disable*/
import { ContractService } from "@app/core/services/contract.service";
import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from "@angular/router";
import { Observable, EMPTY } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class CoversheetByIdResolver implements Resolve<any> {
  constructor(
    private contractService: ContractService,
    private router: Router
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    return this.contractService.getCoversheet(route.paramMap.get("id")).pipe(
      catchError(err => {
        this.router.navigate(["/"]);
        return EMPTY;
      })
    );
  }
}
