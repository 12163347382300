/*eslint-disable*/
import { Injectable } from "@angular/core";
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { Observable, forkJoin, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ContractService } from "../services/contract.service";

@Injectable()
export class LovResolver implements Resolve<any> {
  constructor(private contractService: ContractService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const ucbEntities = this.contractService
      .getLovs("UCB Entity")
      .pipe(catchError(() => of({})));
    const languageGroups = this.contractService
      .getLovs("Language Group")
      .pipe(catchError(() => of({})));
    const originators = this.contractService
      .getLovs("Originator")
      .pipe(catchError(() => of({})));
    const natures = this.contractService
      .getLovs("Contract Type")
      .pipe(catchError(() => of({})));

    return forkJoin(ucbEntities, languageGroups, originators, natures).pipe(
      map(allResponses => {
        return {
          ucbEntities: allResponses[0],
          languageGroups: allResponses[1],
          originators: allResponses[2],
          contractTypes: allResponses[3]
        };
      })
    );
  }
}
