/*eslint-disable*/
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { of, Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { AUTH_KEY, AuthActionTypes, ActionAuthLoginSucceeded } from "./auth.reducer";

@Injectable()
export class AuthEffects {
  constructor(private actions$: Actions<Action>, private localStorageService: LocalStorageService) { }

  public loginSucceeded$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.LOGIN_SUCCEEDED),
      tap((action: ActionAuthLoginSucceeded) => {
        this.localStorageService.setItem(AUTH_KEY, {
          activeAccount: action.activeAccount,
          isAuthenticated: true
        });
      })
    ),
    { dispatch: false }
  );

  public logout$: Observable<Action> = createEffect(
    () => this.actions$.pipe(
      ofType(AuthActionTypes.LOGOUT),
      tap((action: Action) => {
        this.localStorageService.setItem(AUTH_KEY, {
          activeAccount: null,
          isAuthenticated: false
        });
      })
    ),
    { dispatch: false }
  );
}
